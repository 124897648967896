<template>
  <div class="niveaux-revenus">
    <div
      v-for="niveauRevenu in niveauxRevenus" :key="niveauRevenu.name"
      class="niveau-wrapper"
      :class="colors[niveauRevenu.name]">
      <div v-if="niveauRevenu.value" class="value">
        <p v-html="niveauRevenu.value"></p>
      </div>
      <img class="bonus-illu" :src="require(`@/assets/module3/part2/logo-mpr-${colors[niveauRevenu.name]}.svg`)" :alt="$t('global.imageAlts.maprimerenovLogo')">
      <p v-html="niveauRevenu.label"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MprMontantByNiveauRevenu',
  props: {
    niveauxRevenus: {
      type: Array,
      required: true
    },
    showValue: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      colors: {
        tresModeste: 'bleu',
        modeste: 'jaune',
        intermediaire: 'violet',
        aise: 'rose'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.niveaux-revenus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-s;
  .annotation {
    text-align: right;
    margin-top: $space-s;
    font-size: $fz-xs;
  }
  .niveau-wrapper {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $space-s;
    img {
      max-width: 100%;
    }
    p {
      font-size: $fz-xs;
      font-weight: $fw-l;
      text-align: center;
    }
    .value {
      margin-top: $space-sm;
      color: $c-text-dark;
      text-align: center;
      border-radius: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $space-s $space-m;
      p {
        font-size: $fz-m;
        font-weight: $fw-l;
      }
    }
    &.bleu {
      .value {
        background-color: rgba(#b2b7db, 0.4);
      }
    }
    &.jaune {
      .value {
        background-color: rgba(#f6e7a9, 0.4);
      }
    }
    &.violet {
      .value {
        background-color: rgba(#c597a4, 0.4);
      }
    }
    &.rose {
      .value {
        background-color: rgba(#f9dfd1, 0.4);
      }
    }
  }
}

@media (min-width: $bp-phone) {
  .niveaux-revenus {
    flex-direction: row;
  }
}
</style>
